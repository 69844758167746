import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useToast } from "../../../utils/toast";
import calistayServices from "../../../services/calistay/calistay.service";
import { CalistayAnket4_1DTO } from "../../../services/calistay/calistay.dto";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useParams } from "react-router-dom";

interface TableData {
  title: string;
  risk: string;
  tracking: string;
  outcome: string;
}

const CalistayAnket41 = () => {
  const toast = useToast();
  let { id } = useParams();
  const [header, setHeader] = useState<string>("");
  const [items1, setItems1] = useState<string>("");
  const [items2, setItems2] = useState<string>("");
  const [items3, setItems3] = useState<string>("");
  useEffect(() => {
    if (id) {
      if (id === "1") {
        setHeader("Yeşil Dönüşüm Stratejik Amaçlar");
      } else if (id === "2") {
        setHeader("Dijital Dönüşüm Stratejik Amaçlar");
      } else if (id === "3") {
        setHeader("Eğitim Stratejik Amaçlar");
      } else if (id === "4") {
        setHeader("İş Geliştirme Stratejik Amaçlar");
      } else if (id === "5") {
        setHeader("AR-GE Stratejik Amaçlar");
      } else if (id === "6") {
        setHeader("Kapasite Geliştirme Stratejik Amaçlar");
      } else if (id === "7") {
        setHeader("AFET Yönetimi ve İş sürekliliği Stratejik Amaçlar");
      }
    }
  }, [id]);

  const [inputs1, setInputs1] = useState({
    risk1_1: "",
    tracking1_1: "",
    outcome1_1: "",
    risk1_2: "",
    tracking1_2: "",
    outcome1_2: "",
    risk1_3: "",
    tracking1_3: "",
    outcome1_3: "",
    risk1_4: "",
    tracking1_4: "",
    outcome1_4: ""
  });

  const [inputs2, setInputs2] = useState({
    risk2_1: "",
    tracking2_1: "",
    outcome2_1: "",
    risk2_2: "",
    tracking2_2: "",
    outcome2_2: "",
    risk2_3: "",
    tracking2_3: "",
    outcome2_3: "",
    risk2_4: "",
    tracking2_4: "",
    outcome2_4: ""
  });

  const [inputs3, setInputs3] = useState({
    risk3_1: "",
    tracking3_1: "",
    outcome3_1: "",
    risk3_2: "",
    tracking3_2: "",
    outcome3_2: "",
    risk3_3: "",
    tracking3_3: "",
    outcome3_3: "",
    risk3_4: "",
    tracking3_4: "",
    outcome3_4: ""
  });

  useEffect(() => {
    if (localStorage.getItem("userName") === null) {
      toast.show("Kullanıcı adı bulunamadı.", "error");
      return;
    }

    calistayServices
      .Anket4Isimler(localStorage.getItem("userName") ?? "")
      .then(res => {
        if (res?.result?.error === null) {
          setItems1(res.result.data.result1);
          setItems2(res.result.data.result2);
          setItems3(res.result.data.result3);
        } else {
          toast.show("3. Anketi doldurmak için 2. Anketin yapılmış olması gereklidir.", "error");
        }
      })
      .catch(error => {
        toast.show("Sunucu hatası: " + error.message, "error");
      });
  }, []);

  const handleChange1 = (e: any) => {
    setInputs1({
      ...inputs1,
      [e.target.name]: e.target.value
    });
  };

  const handleChange2 = (e: any) => {
    setInputs2({
      ...inputs2,
      [e.target.name]: e.target.value
    });
  };

  const handleChange3 = (e: any) => {
    setInputs3({
      ...inputs3,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async () => {
    try {
      if (id) {
        if (!items1 || !items2 || !items3) {
          toast.show("2. Anketde yorum yapılan isimler alınamadı.Lütfen önce 2. anketi doldurunuz", "error");
          return;
        }
        const dto: CalistayAnket4_1DTO = {
          data_1: items1,
          result1_1: inputs1["risk1_1"],
          result1_2: inputs1["tracking1_1"],
          result1_3: inputs1["outcome1_1"],
          result1_4: inputs1["risk1_2"],
          result1_5: inputs1["tracking1_2"],
          result1_6: inputs1["outcome1_2"],
          result1_7: inputs1["risk1_3"],
          result1_8: inputs1["tracking1_3"],
          result1_9: inputs1["outcome1_3"],
          result1_10: inputs1["risk1_4"],
          result1_11: inputs1["tracking1_4"],
          result1_12: inputs1["outcome1_4"],
          data_2: items2,
          result2_1: inputs2["risk2_1"],
          result2_2: inputs2["tracking2_1"],
          result2_3: inputs2["outcome2_1"],
          result2_4: inputs2["risk2_2"],
          result2_5: inputs2["tracking2_2"],
          result2_6: inputs2["outcome2_2"],
          result2_7: inputs2["risk2_3"],
          result2_8: inputs2["tracking2_3"],
          result2_9: inputs2["outcome2_3"],
          result2_10: inputs2["risk2_4"],
          result2_11: inputs2["tracking2_4"],
          result2_12: inputs2["outcome2_4"],
          data_3: items3,
          result3_1: inputs3["risk3_1"],
          result3_2: inputs3["tracking3_1"],
          result3_3: inputs3["outcome3_1"],
          result3_4: inputs3["risk3_2"],
          result3_5: inputs3["tracking3_2"],
          result3_6: inputs3["outcome3_2"],
          result3_7: inputs3["risk3_3"],
          result3_8: inputs3["tracking3_3"],
          result3_9: inputs3["outcome3_3"],
          result3_10: inputs3["risk3_4"],
          result3_11: inputs3["tracking3_4"],
          result3_12: inputs3["outcome3_4"],
          anket: id
        };

        calistayServices
          .Anket4Kaydet(dto)
          .then(res => {
            if (res?.result?.error === null) {
              toast.show("Form başarıyla kaydedildi!", "success");
            } else {
              toast.show("Form gönderiminde hata oluştu.", "error");
            }
          })
          .catch(error => {
            toast.show("Sunucu hatası: " + error.message, "error");
          });
      }
    } catch (error) {
      console.error(error);
      toast.show("Bir hata oluştu. Lütfen tekrar deneyin.", "error");
    }
  };

  const tableData1: TableData[] = [
    {
      title: "Siber Güvenlik",
      risk: "risk1_1",
      tracking: "tracking1_1",
      outcome: "outcome1_1"
    },
    {
      title: "Veri Yönetimi ve Analitiği",
      risk: "risk1_2",
      tracking: "tracking1_2",
      outcome: "outcome1_2"
    },
    {
      title: "YZ ve Makine Öğrenmesi Kullanımı",
      risk: "risk1_3",
      tracking: "tracking1_3",
      outcome: "outcome1_3"
    },
    {
      title: "İnovasyon ve Teknoloji Geliştirme",
      risk: "risk1_4",
      tracking: "tracking1_4",
      outcome: "outcome1_4"
    }
  ];

  const tableData2: TableData[] = [
    {
      title: "Siber Güvenlik",
      risk: "risk2_1",
      tracking: "tracking2_1",
      outcome: "outcome2_1"
    },
    {
      title: "Veri Yönetimi ve Analitiği",
      risk: "risk2_2",
      tracking: "tracking2_2",
      outcome: "outcome2_2"
    },
    {
      title: "YZ ve Makine Öğrenmesi Kullanımı",
      risk: "risk2_3",
      tracking: "tracking2_3",
      outcome: "outcome2_3"
    },
    {
      title: "İnovasyon ve Teknoloji Geliştirme",
      risk: "risk2_4",
      tracking: "tracking2_4",
      outcome: "outcome2_4"
    }
  ];

  const tableData3: TableData[] = [
    {
      title: "Siber Güvenlik",
      risk: "risk3_1",
      tracking: "tracking3_1",
      outcome: "outcome3_1"
    },
    {
      title: "Veri Yönetimi ve Analitiği",
      risk: "risk3_2",
      tracking: "tracking3_2",
      outcome: "outcome3_2"
    },
    {
      title: "YZ ve Makine Öğrenmesi Kullanımı",
      risk: "risk3_3",
      tracking: "tracking3_3",
      outcome: "outcome3_3"
    },
    {
      title: "İnovasyon ve Teknoloji Geliştirme",
      risk: "risk3_4",
      tracking: "tracking3_4",
      outcome: "outcome3_4"
    }
  ];

  const inputTemplate1 = (rowData: TableData, field: keyof TableData) => {
    const fieldName = rowData[field] as keyof typeof inputs1;
    return <InputText value={inputs1[fieldName]} name={fieldName} onChange={handleChange1} />;
  };

  const inputTemplate2 = (rowData: TableData, field: keyof TableData) => {
    const fieldName = rowData[field] as keyof typeof inputs2;
    return <InputText value={inputs2[fieldName]} name={fieldName} onChange={handleChange2} />;
  };

  const inputTemplate3 = (rowData: TableData, field: keyof TableData) => {
    const fieldName = rowData[field] as keyof typeof inputs3;
    return <InputText value={inputs3[fieldName]} name={fieldName} onChange={handleChange3} />;
  };

  return (
    <div className="card">
      <h5 className="text-center mb-4 font-medium">{header}</h5>

      <div className="w-full surface-card">
        <h4>{items1}</h4>
        <DataTable value={tableData1} showGridlines tableStyle={{ minWidth: "60rem" }}>
          <Column field="title" header="Başlık" />
          <Column header="Riskler neler olabilir" body={rowData => inputTemplate1(rowData, "risk")} />
          <Column header="Nasıl takip etmeli" body={rowData => inputTemplate1(rowData, "tracking")} />
          <Column header="Sonuç ne olabilir" body={rowData => inputTemplate1(rowData, "outcome")} />
        </DataTable>
        <h4>{items2}</h4>

        <DataTable value={tableData2} showGridlines tableStyle={{ minWidth: "60rem" }}>
          <Column field="title" header="Başlık" />
          <Column header="Riskler neler olabilir" body={rowData => inputTemplate2(rowData, "risk")} />
          <Column header="Nasıl takip etmeli" body={rowData => inputTemplate2(rowData, "tracking")} />
          <Column header="Sonuç ne olabilir" body={rowData => inputTemplate2(rowData, "outcome")} />
        </DataTable>

        <h4>{items3}</h4>
        <DataTable value={tableData3} showGridlines tableStyle={{ minWidth: "60rem" }}>
          <Column field="title" header="Başlık" />
          <Column header="Riskler neler olabilir" body={rowData => inputTemplate3(rowData, "risk")} />
          <Column header="Nasıl takip etmeli" body={rowData => inputTemplate3(rowData, "tracking")} />
          <Column header="Sonuç ne olabilir" body={rowData => inputTemplate3(rowData, "outcome")} />
        </DataTable>
      </div>

      <div
        className="m-2 md:m-3"
        style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", flexWrap: "wrap", gap: "0.5rem" }}
      >
        <Button type="submit" label="Gönder" onClick={handleSubmit} className="p-button-success" />
      </div>
    </div>
  );
};

export default CalistayAnket41;
