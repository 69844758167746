import React, { useContext, useEffect, useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { LayoutContext } from "../../layout/context/layoutcontext";
import { NodeRef } from "../../types/index";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { CarouselResponsiveOption, Carousel } from "primereact/carousel";
import { Tag } from "primereact/tag";
import { Card } from "primereact/card";
import "../../assets/styles/hover.scss";
import { Divider } from "primereact/divider";

interface ItemDto {
  name: string;
  description: string;
  image: string;
  link: string;
}

const BaskanMesaj = () => {


  return (
    <div className="surface-0 flex justify-content-center">
         <div className="container card">
        <h1>Yeşil dönüşüm ve sürdürülebilirlik merkezinin değerli takipçileri</h1>
        <p>
            OSB'ler, ülkemizin sanayi altyapısının bel kemiğidir. Türkiye'nin üretim gücünün önemli bir bölümünü barındıran OSB'ler, sanayide sürdürülebilirlik ve dijitalleşme süreçlerinde lider rol oynamak zorundadır. Yeşil ve dijital dönüşüm, sadece çevresel bir sorumluluk değil, aynı zamanda rekabet gücümüzü korumanın ve sanayimizin geleceğe uyum sağlamasının anahtarıdır. Bugün burada hep birlikte, geleceğimiz için somut adımlar atacağız. Sizlerin fikirleri, stratejik planımızın başarısında kilit rol oynayacaktır. Bu dönüşüm, OSB'lerimizden başlamalı ve sanayinin diğer alanlarına yayılmalıdır. Çünkü OSB'lerimiz, bu dönüşümün yayılmasında ve başarıya ulaşmasında en önemli rolü üstlenecek potansiyele sahiptir.
        </p>
        
        <h2>Yeşil Dönüşüm: Sürdürülebilir Gelecek İçin Zorunluluk</h2>
        <p>
            Dünyamız, çevresel sürdürülebilirlik konusunda kritik bir dönemeçten geçiyor. İklim değişikliği ve doğal kaynakların hızla tükenmesi, sanayinin yeşil dönüşümünü kaçınılmaz kılıyor. OSB'lerimiz, çevresel etkilerini minimize eden, enerji verimliliğini artıran ve yenilenebilir enerji kaynaklarını daha etkin kullanan bir üretim modeline geçmelidir. Bu dönüşüm, sadece çevreye duyarlı bir yaklaşım sergilemekle kalmayacak, aynı zamanda uluslararası ticarette önemli bir avantaj sağlayacaktır. Önümüzdeki beş yıl içinde, OSB'lerimizin karbon ayak izini azaltmayı ve enerji verimliliğini artırmayı hedefliyoruz. Bu hedefler, geleceğe daha sürdürülebilir bir sanayi mirası bırakmamızı sağlayacaktır.
        </p>
        <p>
            Avrupa Birliği tarafından uygulamaya konulan Sınırda Karbon Düzenleme Mekanizması (SKDM) gibi uluslararası düzenlemeler, karbon ayak izini azaltmayan üreticiler için ek maliyetler getirecek. OSB'lerimiz, yeşil dönüşüme öncülük ederek bu tür risklerden korunmalı ve sürdürülebilir üretim süreçlerine hızla geçiş yapmalıdır. OSB'lerimizin yeşil dönüşüme liderlik etmesi, sadece ülkemizin uluslararası pazarlardaki rekabet gücünü artırmakla kalmayacak, aynı zamanda çevresel sorumluluğumuzu yerine getirme açısından da büyük bir önem taşıyacaktır. Dünya’da pek çok ülkede sanayicilerin yeşil dönüşümdeki başarı hikayesi hepimize ilham verebilir. Bu dönüşümü başaran her OSB, küresel pazarda bir adım öne çıkacaktır.
        </p>

        <h2>Dijital Dönüşüm: Sanayimizin Geleceği</h2>
        <p>
            Sanayimizin dijitalleşmesi, küresel rekabet gücünü artıracak ve verimlilik seviyelerini üst noktalara taşıyacaktır. OSB'lerimizde dijital teknolojilerin entegrasyonu, üretim süreçlerinde büyük verimlilik artışlarına yol açacaktır. Akıllı fabrikalar, büyük veri analitiği, yapay zeka ve nesnelerin interneti (IoT) gibi dijital dönüşüm araçları, hem maliyetlerin düşürülmesini hem de çevresel etkilerin azaltılmasını sağlayacaktır. Önümüzdeki süreçte, dijital teknolojilere daha fazla yatırım yaparak üretim süreçlerimizi daha esnek ve verimli hale getirmeyi hedefliyoruz.
        </p>

        <h2>Stratejik Planın Önemi ve OSB'lerin Rolü</h2>
        <p>
            Bugün burada temellerini attığımız stratejik plan, OSB’lerimizin hem yeşil dönüşüm hem de dijitalleşme süreçlerinde öncü rol üstlenmesini sağlayacak. Bu iki alanın birbirinden ayrı düşünülemeyeceğini biliyoruz. Çevresel sürdürülebilirliği sağlarken aynı zamanda dijital teknolojileri etkin bir şekilde kullanmak, sanayimizin geleceğini şekillendirecek temel unsurlardır. OSB'lerimiz, bu dönüşüm süreçlerinin öncüsü olarak Türkiye'nin sanayi devrimine liderlik etmelidir.
        </p>

        <p>
            OSB'lerimizin yeşil ve dijital dönüşüme liderlik etmesi, sanayimizin sürdürülebilir ve yenilikçi yapıya kavuşmasının temel taşlarından biri olacaktır. Hep birlikte geleceğe emin adımlarla yürüyoruz. Katkılarınız ve iş birliğiniz için teşekkür ederim.
        </p>
    </div>
    </div>
  );
};

export default BaskanMesaj;
