import React, { useContext } from "react";
import AppMenuitem from "./AppMenuitem";
import { LayoutContext } from "./context/layoutcontext";
import { MenuProvider } from "./context/menucontext";
import { AppMenuItem } from "../types/types";

const AppMenu = () => {
  //const { layoutConfig } = useContext(LayoutContext);
  const masaNo = localStorage.getItem("anketNo") || "1";

  const menuItems = [
    {
      label: "Anketler",
      icon: "pi pi-fw pi-home",
      items: [
        {
          label: "Anket 1",
          icon: "pi pi-fw pi-home",
          items: [
            { label: "Anket 1.1", icon: "pi pi-fw pi-home", to: `/calistay/anket-alan-1` },
            { label: "Anket 1.1 Sonuc", icon: "pi pi-fw pi-home", to: `/calistay/anket-alan-1-sonuc` },
            { label: "Anket 1.2", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-2" },
            { label: "Anket 1.2 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-2-sonuc" },
            { label: "Anket 1.3", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-3" },
            { label: "Anket 1.3 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-3-sonuc" },
            { label: "Anket 1.4", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-4" },
            { label: "Anket 1.4 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-4-sonuc" },
            { label: "Anket 1.5", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-5" },
            { label: "Anket 1.5 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-5-sonuc" },
            { label: "Anket 1.6", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-6" },
            { label: "Anket 1.6 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-6-sonuc" },
            { label: "Anket 1.7", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-7" },
            { label: "Anket 1.7 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-7-sonuc" }
          ]
        },
        {
          label: "Anket 2",
          icon: "pi pi-fw pi-home",
          items: [
            { label: "Anket 2.1", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/1" },
            { label: "Anket 2.1 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/1" },
            { label: "Anket 2.2", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/2" },
            { label: "Anket 2.2 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/2" },
            { label: "Anket 2.3", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/3" },
            { label: "Anket 2.3 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/3" },
            { label: "Anket 2.4", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/4" },
            { label: "Anket 2.4 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/4" },
            { label: "Anket 2.5", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/5" },
            { label: "Anket 2.5 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/5" },
            { label: "Anket 2.6", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/6" },
            { label: "Anket 2.6 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/6" },
            { label: "Anket 2.7", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/7" },
            { label: "Anket 2.7 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/7" }
          ]
        },
        {
          label: "Anket 3",
          icon: "pi pi-fw pi-home",
          items: [
            { label: "Anket 3.1", icon: "pi pi-fw pi-home", to: "/calistay/anket4/1" },
            { label: "Anket 3.1 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/1" },
            { label: "Anket 3.2", icon: "pi pi-fw pi-home", to: "/calistay/anket4/2" },
            { label: "Anket 3.2 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/2" },
            { label: "Anket 3.3", icon: "pi pi-fw pi-home", to: "/calistay/anket4/3" },
            { label: "Anket 3.3 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/3" },
            { label: "Anket 3.4", icon: "pi pi-fw pi-home", to: "/calistay/anket4/4" },
            { label: "Anket 3.4 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/4" },
            { label: "Anket 3.5", icon: "pi pi-fw pi-home", to: "/calistay/anket4/5" },
            { label: "Anket 3.5 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/5" },
            { label: "Anket 3.6", icon: "pi pi-fw pi-home", to: "/calistay/anket4/6" },
            { label: "Anket 3.6 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/6" },
            { label: "Anket 3.7", icon: "pi pi-fw pi-home", to: "/calistay/anket4/7" },
            { label: "Anket 3.7 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/7" }
          ]
        }
      ]
    }
  ];

  const model: AppMenuItem[] = [
    {
      label: "Ana Sayfa",
      items: [{ label: "Ana Sayfa", icon: "pi pi-fw pi-home", to: "/" }]
    },
    {
      label: "Sayfalar",
      icon: "pi pi-fw pi-briefcase",
      to: "/pages",
      items: [
        {
          label: "Hesaplama",
          icon: "pi pi-fw pi-calculator",
          to: "/app/karbonayakizi"
        },
        {
          label: "Raporlama",
          icon: "pi pi-fw pi-file",
          to: "/app/malzeme-kaynak"
        },
        {
          label: "Analiz",
          icon: "pi pi-fw pi-chart-scatter",
          to: "/app/grafik"
        },
        {
          label: "Eğitim",
          icon: "pi pi-fw pi-user",
          to: "/app/egitim"
        },
        {
          label: "İş Sürekliliği Planı",
          icon: "pi pi-fw pi-info-circle",
          to: "/app/is-surekliligi"
        },
        {
          label: "OSBÜK Bülten",
          icon: "pi pi-fw pi-bell",
          to: "/app/bulten"
        },
        {
          label: "Başkan Mesajı",
          icon: "pi pi-fw pi-bell",
          to: "/app/baskan-mesaj"
        }
      ]
    },
    {
      label: "Anketler",
      icon: "pi pi-fw pi-briefcase",
      items: [
        {
          label: "Anket 1",
          icon: "pi pi-fw pi-calculator",
          to: `/calistay/anket-alan-${masaNo}`
        },
        {
          label: "Anket 1 Sonuc",
          icon: "pi pi-fw pi-file",
          to: `/calistay/anket-alan-${masaNo}-sonuc`
        },
        {
          label: "Anket 2",
          icon: "pi pi-fw pi-chart-scatter",
          to: `/calistay/anket-yorum/${masaNo}`
        },
        {
          label: "Anket 2 Sonuc",
          icon: "pi pi-fw pi-user",
          to: `/calistay/anket-yorum-sonuc/${masaNo}`
        },
        {
          label: "Anket 3",
          icon: "pi pi-fw pi-info-circle",
          to: `/calistay/anket4/${masaNo}`
        },
        {
          label: "Anket 3 Sonuc",
          icon: "pi pi-fw pi-bell",
          to: `/calistay/anket4-sonuc/${masaNo}`
        }
      ]
    },

    // {
    //   label: "Anketler",
    //   icon: "pi pi-fw pi-home",
    //   items: [
    //     {
    //       label: "Anket 2",
    //       icon: "pi pi-fw pi-home",
    //       items: [
    //         { label: "Anket 2.1", icon: "pi pi-fw pi-home", to: `/calistay/anket-alan-1` },
    //         { label: "Anket 2.1 Sonuc", icon: "pi pi-fw pi-home", to: `/calistay/anket-alan-1-sonuc` },
    //         { label: "Anket 2.2", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-2" },
    //         { label: "Anket 2.2 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-2-sonuc" },
    //         { label: "Anket 2.3", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-3" },
    //         { label: "Anket 2.3 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-3-sonuc" },
    //         { label: "Anket 2.4", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-4" },
    //         { label: "Anket 2.4 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-4-sonuc" },
    //         { label: "Anket 2.5", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-5" },
    //         { label: "Anket 2.5 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-5-sonuc" },
    //         { label: "Anket 2.6", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-6" },
    //         { label: "Anket 2.6 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-6-sonuc" },
    //         { label: "Anket 2.7", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-7" },
    //         { label: "Anket 2.7 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-7-sonuc" }
    //       ]
    //     },
    //     {
    //       label: "Anket 3",
    //       icon: "pi pi-fw pi-home",
    //       items: [
    //         { label: "Anket 3.1", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/1" },
    //         { label: "Anket 3.1 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/1" },
    //         { label: "Anket 3.2", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/2" },
    //         { label: "Anket 3.2 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/2" },
    //         { label: "Anket 3.3", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/3" },
    //         { label: "Anket 3.3 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/3" },
    //         { label: "Anket 3.4", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/4" },
    //         { label: "Anket 3.4 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/4" },
    //         { label: "Anket 3.5", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/5" },
    //         { label: "Anket 3.5 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/5" },
    //         { label: "Anket 3.6", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/6" },
    //         { label: "Anket 3.6 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/6" },
    //         { label: "Anket 3.7", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/7" },
    //         { label: "Anket 3.7 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/7" }
    //       ]
    //     },
    //     {
    //       label: "Anket 4",
    //       icon: "pi pi-fw pi-home",
    //       items: [
    //         { label: "Anket 4.1", icon: "pi pi-fw pi-home", to: "/calistay/anket4/1" },
    //         { label: "Anket 4.1 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/1" },
    //         { label: "Anket 4.2", icon: "pi pi-fw pi-home", to: "/calistay/anket4/2" },
    //         { label: "Anket 4.2 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/2" },
    //         { label: "Anket 4.3", icon: "pi pi-fw pi-home", to: "/calistay/anket4/3" },
    //         { label: "Anket 4.3 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/3" },
    //         { label: "Anket 4.4", icon: "pi pi-fw pi-home", to: "/calistay/anket4/4" },
    //         { label: "Anket 4.4 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/4" },
    //         { label: "Anket 4.5", icon: "pi pi-fw pi-home", to: "/calistay/anket4/5" },
    //         { label: "Anket 4.5 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/5" },
    //         { label: "Anket 4.6", icon: "pi pi-fw pi-home", to: "/calistay/anket4/6" },
    //         { label: "Anket 4.6 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/6" },
    //         { label: "Anket 4.7", icon: "pi pi-fw pi-home", to: "/calistay/anket4/7" },
    //         { label: "Anket 4.7 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket4-sonuc/7" }
    //       ]
    //     }
    //   ]
    // }
    // {
    //   label: "Çalıştay",
    //   icon: "pi pi-fw pi-briefcase",
    //   items: [
    //     { label: "Çalıştay", icon: "pi pi-fw pi-home", to: "/calistay/home" },
    //     { label: "Oturma Planı", icon: "pi pi-fw pi-home", to: "/calistay/masa-sonuc-liste" },

    //     { label: "Masa Belirle", icon: "pi pi-fw pi-home", to: "/calistay/masa-sonuc" },

    //     { label: "Anket 1", icon: "pi pi-fw pi-home", to: "/calistay/anket-1" },
    //     { label: "Anket 2.1", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-1" },
    //     { label: "Anket 2.1 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-1-sonuc" },
    //     { label: "Anket 2.2", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-2" },
    //     { label: "Anket 2.2 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-2-sonuc" },
    //     { label: "Anket 2.3", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-3" },
    //     { label: "Anket 2.3 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-3-sonuc" },
    //     { label: "Anket 2.4", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-4" },
    //     { label: "Anket 2.4 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-4-sonuc" },
    //     { label: "Anket 2.5", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-5" },
    //     { label: "Anket 2.5 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-5-sonuc" },
    //     { label: "Anket 2.6", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-6" },
    //     { label: "Anket 2.6 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-6-sonuc" },
    //     { label: "Anket 2.7", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-7" },
    //     { label: "Anket 2.7 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-alan-7-sonuc" },

    //     { label: "Anket 3.1", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/1" },
    //     { label: "Anket 3.1 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/1" },
    //     { label: "Anket 3.2", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/2" },
    //     { label: "Anket 3.2 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/2" },
    //     { label: "Anket 3.3", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/3" },
    //     { label: "Anket 3.3 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/3" },
    //     { label: "Anket 3.4", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/4" },
    //     { label: "Anket 3.4 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/4" },
    //     { label: "Anket 3.5", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/5" },
    //     { label: "Anket 3.5 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/5" },
    //     { label: "Anket 3.6", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/6" },
    //     { label: "Anket 3.6 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/6" },
    //     { label: "Anket 3.7", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum/7" },
    //     { label: "Anket 3.7 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket-yorum-sonuc/7" },

    //     { label: "Anket 4.1", icon: "pi pi-fw pi-home", to: "/calistay/anket41" },
    //     { label: "Anket 4.1 Sonuc", icon: "pi pi-fw pi-home", to: "/calistay/anket41-sonuc" },
    //   ]
    // }
  ];
  if (localStorage.getItem("role") === "0") {
    model.push(...menuItems);
  }

  return (
    <MenuProvider>
      <ul className="layout-menu">
        {model.map((item, i) => {
          return !item?.seperator ? (
            <AppMenuitem item={item} root={true} index={i} key={item.label} />
          ) : (
            <li className="menu-separator"></li>
          );
        })}
      </ul>
    </MenuProvider>
  );
};

export default AppMenu;
