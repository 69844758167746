import React, { useContext, useEffect, useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";

import { classNames } from "primereact/utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useToast } from "../../../utils/toast";
import calistayServices from "../../../services/calistay/calistay.service";
import { YorumSonuc } from "../../../services/calistay/calistay.dto";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const CalistayAnketYorum1Sonuc = () => {
  const toast = useToast();
  let { id } = useParams();

  const [items, setItems] = useState<YorumSonuc[]>([]);
  const [header, setHeader] = useState<string>("");
  useEffect(() => {    
    if (id) {
      if (id === "1") {
        setHeader("Yeşil Dönüşüm Stratejik Amaçlar");
      } else if (id === "2") {
        setHeader("Dijital Dönüşüm Stratejik Amaçlar");
      } else if (id === "3") {
        setHeader("Eğitim Stratejik Amaçlar");
      } else if (id === "4") {
        setHeader("İş Geliştirme Stratejik Amaçlar");
      } else if (id === "5") {
        setHeader("AR-GE Stratejik Amaçlar");
      } else if (id === "6") {
        setHeader("Kapasite Geliştirme Stratejik Amaçlar");
      } else if (id === "7") {
        setHeader("AFET Yönetimi ve İş sürekliliği Stratejik Amaçlar");
      }
    }
  }, [id]);
  useEffect(() => {
    if (localStorage.getItem("userName") === null) {
      toast.show("Kullanıcı adı bulunamadı.", "error");
      return;
    }
    if (id) {
      calistayServices
        .AnketYorumSonuc1(id)
        .then(res => {
          if (res?.result?.error === null) {
            setItems(res.result.data);
          } else {
            toast.show("Hata oluştu.", "error");
          }
        })
        .catch(error => {
          toast.show("Sunucu hatası: " + error.message, "error");
        });
    }
  }, []);

  return (
    <div className="card ">
      <h5 className="text-center mb-4 font-medium">{header}</h5>

      <div className="w-full surface-card">
        <DataTable
          value={items}
          showGridlines
          tableStyle={{ minWidth: "60rem" }}
          rows={500}
          paginator // Sayfalama eklemek için
          scrollable
          columnResizeMode="expand"
          sortField="adet" // Sort by "Adı" field
          sortOrder={-1} // 1 for ascending, -1 for descending
        >
          <Column field="name" header="Adı"></Column>
          <Column field="adet" header="Toplam Yorum Sayısı"></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default CalistayAnketYorum1Sonuc;
