import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useToast } from "../../utils/toast";
import { Button } from "primereact/button";
import calistayServices from "../../services/calistay/calistay.service";
import { CalistayAnket1 } from "../../services/calistay/calistay.dto";

export interface SurukleBırakListItem {
  id: string;
  content: string;
}

const CalistayAnketSurukleBirak = () => {
  const toast = useToast();

  const initialItems = [
    { content: "Yeşil Dönüşüm", id: "1" },
    { content: "Dijital Dönüşüm", id: "2" },
    { content: "Eğitim", id: "3" },
    { content: "İş Geliştirme", id: "4" },
    { content: "ARGE", id: "5" },
    { content: "Kapasite geliştirme", id: "6" },
    { content: "İş sürekliliği Planı", id: "7" }
  ];

  const [items, setItems] = useState<SurukleBırakListItem[]>(initialItems);
  const [droppedItems, setDroppedItems] = useState<SurukleBırakListItem[]>([]);

  const MAX_TARGET_ITEMS = 3;

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Hedef alana taşıma kontrolü
    if (
      destination.droppableId === "droppable-target" &&
      source.droppableId === "droppable-source" &&
      droppedItems.length >= MAX_TARGET_ITEMS
    ) {
      toast.show("Maksimum 3 stratejik alan seçebilirsiniz.", "error");
      return;
    }

    // Aynı liste içinde taşıma
    if (source.droppableId === destination.droppableId) {
      const list = source.droppableId === "droppable-source" ? [...items] : [...droppedItems];
      const [removed] = list.splice(source.index, 1);
      list.splice(destination.index, 0, removed);

      if (source.droppableId === "droppable-source") {
        setItems(list);
      } else {
        setDroppedItems(list);
      }
    }
    // Listeler arası taşıma
    else {
      const sourceList = source.droppableId === "droppable-source" ? [...items] : [...droppedItems];
      const destList = destination.droppableId === "droppable-source" ? [...items] : [...droppedItems];

      const [removed] = sourceList.splice(source.index, 1);
      destList.splice(destination.index, 0, removed);

      if (source.droppableId === "droppable-source") {
        setItems(sourceList);
        setDroppedItems(destList);
      } else {
        setItems(destList);
        setDroppedItems(sourceList);
      }
    }
  };

  const handleSubmit = () => {
    if (droppedItems.length === 3) {
      const dto: CalistayAnket1 = {
        result1: droppedItems[0].content,
        result2: droppedItems[1].content,
        result3: droppedItems[2].content,
        birim: "",
        kurum: "",
        bolge: ""
      };

      calistayServices
        .AnketStratejikAlanlar1(dto)
        .then(res => {
          if (res?.result?.error === null) {
            toast.show("Form başarıyla kaydedildi!", "success");
          } else {
            toast.show("Form gönderiminde hata oluştu.", "error");
          }
        })
        .catch(error => {
          toast.show("Sunucu hatası: " + error.message, "error");
        });
    } else {
      toast.show("Lütfen 3 stratejik alan seçiniz.", "error");
    }
  };

  return (
    <>
      <h5 className="text-center mb-4 font-medium">Sürükleyip Bırakmak için Alanlar</h5>

      <div className="flex w-full p-4">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="flex justify-between items-start w-full p-4">
            <Droppable droppableId="droppable-source">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="w-full bg-gray-100 p-4 rounded">
                  <h5 className="mb-4 font-medium">Kaynak Alan</h5>
                  {items.map(({ id, content }, index) => (
                    <Draggable key={id} draggableId={id} index={index}>
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="p-2 mb-2 bg-white rounded border border-gray-200"
                        >
                          {content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <div className="text-center p-4 mt-7">
              <h5>
                &#x21C4; Sizin için en önemli 3 stratejik alanı sıralı bir şekilde hedef alanına sürükleyip bırakınız
                &#x21C4;
              </h5>
            </div>

            <Droppable droppableId="droppable-target">
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="w-full bg-green-100 p-4 min-h-[150px] rounded"
                >
                  <h5 className="mb-4 font-medium">Hedef Alan</h5>
                  {droppedItems.map(({ id, content }, index) => (
                    <Draggable key={id} draggableId={id} index={index}>
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="p-2 mb-2 bg-white rounded border border-gray-200 flex items-center"
                        >
                          <span className="mr-4">{index + 1}.</span> {/* Sıra numarası */}
                          {content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>

      <div className="mr-4" style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button label="Gönder" onClick={handleSubmit} disabled={droppedItems.length !== 3} />
      </div>
    </>
  );
};

export default CalistayAnketSurukleBirak;
