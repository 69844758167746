// ProtectedRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  element: React.ReactElement; // Use React.ReactElement for the element prop
  requiredRole: string; // Role that is required to access the route
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, requiredRole }) => {
  const userRole = localStorage.getItem("role"); // Get the user's role from localStorage
  const userName = localStorage.getItem("userName");

  //   if (!userRole && userName !== null && userRole !== requiredRole) {
  //     return element;
  //   }

  // Check if the user is logged in
  if (!userName) {
    return <Navigate to="/login" />; // Redirect to login page if not authenticated
  }

  if (requiredRole === "-1" || requiredRole === userRole) {
    return element;
  }

  // Redirect to not-found if the role does not match
  return <Navigate to="/not-found" />;
};

export default ProtectedRoute;
