import React, { useContext, useEffect, useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { LayoutContext } from "../../layout/context/layoutcontext";
import { NodeRef } from "../../types/index";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { CarouselResponsiveOption, Carousel } from "primereact/carousel";
import { Tag } from "primereact/tag";
import { Card } from "primereact/card";
import "../../assets/styles/hover.scss";
import { Divider } from "primereact/divider";

interface ItemDto {
  name: string;
  description: string;
  image: string;
  link: string;
}

const LandingPage = () => {
  const [isHidden, setIsHidden] = useState(false);
  const { layoutConfig } = useContext(LayoutContext);
  const menuRef = useRef<HTMLElement | null>(null);
  const navigate = useNavigate();

  const toggleMenuItemClick = () => {
    setIsHidden(prevState => !prevState);
  };

  const crouselItems: any = [
    {
      name: "Stratejik plan raporu",
      description: "Paydaş beklenti ve ihtiyaç analizi (2.10.2024)",
      image: "/images/landing-page/stratejik-plan.jpg"
      //link: "/app/stratejik-plan-raporu"
    },
    {
      name: "Stratejik plan çalıştayı",
      description: "Amaçlar çalıştay çalışmaları (31 Ekim - 3 Kasım 2024)",
      image: "/images/landing-page/sunum.jpg",
      link: "/calistay/home"
    },
    {
      name: "Hesaplama",
      description: "Gerekli hesaplamalara buradan ulaşabilirsiniz.",
      image: "/images/landing-page/hesaplama.jpg",
      link: "/app/hesaplama"
    },
    {
      name: "Raporlama",
      description: "Sürdürülebilirlik ve çevresel performans raporları.",
      image: "/images/landing-page/rapor.jpg",
      link: "/app/raporlama"
    },
    {
      name: "Analiz",
      description: "Yeşil dönüşüm olgunluk analizi ve istatistikler.",
      image: "/images/landing-page/analiz.jpg",
      link: "/app/analiz"
    },
    {
      name: "Eğitim",
      description: "Geniş kapsamlı eğitim fırsatları",
      image: "/images/landing-page/egitim.jpg",
      link: "/app/egitim"
    },
    {
      name: "İş Sürekliliği",
      description: "Amaçlar çalıştay çalışmaları (31 Ekim - 3 Kasım 2024)",
      image: "/images/landing-page/afad.jpg",
      link: "/app/is-surekliligi"
    },
    {
      name: "OSBÜK Bülten",
      description: "Güncel OSB haberleri bülteni",
      image: "/images/landing-page/haber.jpg",
      link: "/app/osbuk-bulten"
    }
  ];

  const itemTemplate = (item: ItemDto) => {
    return (
      <a href={item.link} className="border-1 surface-border border-round m-1 text-center py-3 px-2 h-15rem block">
        <div className="mb-3">
          <img
            src={item.image}
            alt={item.name}
            className="w-6rem h-6rem object-cover shadow-2" // 6rem x 6rem boyutunu ayarladık
          />
        </div>
        <div>
          <h4 className="mb-1 text-white text-2xl">{item.name}</h4>
          <p className="text-white text-sm">{item.description}</p>
        </div>
      </a>
    );
  };

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
      numScroll: 5
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1
    }
  ];

  const username = localStorage.getItem('userName');

  return (
    <div className="surface-0 flex justify-content-center">
      <div id="home" className="landing-wrapper overflow-hidden">
        <div
          className="py-1 px-4 mx-0 flex align-items-center justify-content-between relative lg:static"
          //style={{ backgroundColor: "#114d6b" }}
          style={{ backgroundColor: "#114d6b" }}
        >
          <Link to="/" className="flex align-items-center">
            <img src="/images/logoosbuk.png" alt="OSBÜK" height="50" className="mr-3 lg:mr-3" />
          </Link>

          <div className="lg:flex ml-auto">
            <Button
              label={username ? username : "Giriş Yap"} // username varsa göster, yoksa "Giriş Yap" göster
              rounded
              className="font-bold line-height-1"
              style={{
                backgroundColor: "#ffffff", // White background
                color: "#114d6b", // Text color #114d6b
                border: "3px solid #114d6b" // Border color #114d6b
              }}
              onClick={() => {
                navigate("/login");
              }}
            ></Button>
          </div>
        </div>
        <div style={{ backgroundColor: "#114d6b", height: "4px" }}>
          <br />
        </div>

        <div
          id="features"
          className="flex justify-content-center align-items-center py-4 px-4 lg:px-6 border-green-300 rounded-lg shadow-md"
          style={{
            backgroundColor: "#114d6b",
            backgroundImage: "url('images/bgi.webp')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "25rem"
          }}
        >
          <div className="text-center">
            <h1 className="text-3xl font-bold" style={{ color: "#114d6b" }}>
              YEŞİL DÖNÜŞÜM VE SÜRDÜRÜLEBİLİRLİK MERKEZİ
            </h1>
            <p className="mt-2" style={{ color: "#114d6b" }}>
              Organize Sanayi Bölgelerine (OSB) yeşil dönüşümde rehberlik ediyor, sürdürülebilirlik alanında sağlam
              adımlarla ilerleyen bir topluluk inşa ediyoruz.
            </p>
          </div>
        </div>

        <div id="features" className="py-2 px-2 lg:px-4 mt-2 mx-0 lg:mx-4">
          <div className="grid justify-content-center">
            <div className="col-12 md:col-6 lg:col-4">
              <div
                className="card hover-card cursor-pointer"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/karbonayakizi", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/landing-page/hesaplama.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Hesaplama</h5>
                    <span className="text-600 text-sm">
                      Yeşil dönüşüm ve sürdürülebilirlik politikalarınızı planlamak için gerekli hesaplamalar.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4">
              <div
                className="card hover-card cursor-pointer"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/malzeme-kaynak", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/landing-page/rapor.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Raporlama</h5>
                    <span className="text-600 text-sm">
                      Sürdürülebilirlik, çevresel performans, iyileştirme ve malzeme-kaynak yönetimi raporları.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4">
              <div
                className="card hover-card cursor-pointer"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/grafik", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/landing-page/analiz.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Analiz</h5>
                    <span className="text-600 text-sm">
                      Yeşil dönüşüm olgunluk analizi, dijital dönüşüm endeksi ve sektör/bölge/ülke genelindeki
                      istatistikler ve raporlar.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4">
              <div
                className="card hover-card cursor-pointer"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/egitim", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/landing-page/egitim.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Eğitim</h5>
                    <span className="text-600 text-sm">
                      Sürdürülebilirlik, yenilikçilik, teknoloji, kurumsal gelişim ve iklim değişikliğine uyum
                      eğitimleri.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4">
              <div
                className="card hover-card cursor-pointer"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/is-surekliligi", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/landing-page/afad.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">İş Sürekliliği Planı</h5>
                    <span className="text-600 text-sm">
                      Afet durumunda sanayi kuruluşlarının kesintisiz faaliyet göstermesi için AFAD ve Sanayi Bakanlığı
                      ile geliştirdiğimiz işbirliği çalışmaları.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4">
              <div
                className="card hover-card cursor-pointer"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/bulten", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/landing-page/haber.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">OSBÜK Bülten</h5>
                    <span className="text-600 text-sm">
                      Türkiye genelindeki organize sanayi bölgeleriyle ilgili güncel haberler, projeler ve sektörel
                      gelişmeler.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="features"
          className="py-4 px-4 mt-5 mx-0"
          // style={{ backgroundColor: "#114d6b" }}
        >
          <div className="flex align-items-center justify-content-center">
            <a href="/app/baskan-mesaj">
              <img src="images/baskanmesaj.jpg" alt="" />
            </a>
          </div>
        </div>

        <div id="features" className="py-4 px-4 mt-5 mx-0" style={{ backgroundColor: "#114d6b" }}>
          <Carousel
            value={crouselItems}
            numVisible={5} // default number for large screens
            responsiveOptions={responsiveOptions}
            className=""
            circular
            autoplayInterval={3000}
            itemTemplate={itemTemplate}
          />
        </div>

        <div className="py-4 px-4 mx-0 mt-4 lg:mx-4">
          <div className="grid justify-content-between">
            <div className="col-12 md:col-2" style={{ marginTop: "-1.5rem" }}>
              <Link
                to="/"
                className="flex align-items-center justify-content-center md:justify-content-start  cursor-pointer"
              >
                <img src="images/medeasofticon.jpg" alt="footer sections" width="80" height="80" className="mr-2" />
                <span className="font-medium text-3xl text-900">MedeaSoft</span>
              </Link>
            </div>

            {/* <div className="col-12 md:col-10 lg:col-7">
              <div className="grid text-center md:text-left">
                <div className="col-12 md:col-3">
                  <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Company</h4>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">About Us</a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">News</a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Investor Relations</a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Careers</a>
                  <a className="line-height-3 text-xl block cursor-pointer text-700">Media Kit</a>
                </div>

                <div className="col-12 md:col-3 mt-4 md:mt-0">
                  <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Resources</h4>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Get Started</a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Learn</a>
                  <a className="line-height-3 text-xl block cursor-pointer text-700">Case Studies</a>
                </div>

                <div className="col-12 md:col-3 mt-4 md:mt-0">
                  <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Community</h4>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Discord</a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">
                    Events
                    <img src="/images/new-badge.svg" className="ml-2" alt="badge" />
                  </a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">FAQ</a>
                  <a className="line-height-3 text-xl block cursor-pointer text-700">Blog</a>
                </div>

                <div className="col-12 md:col-3 mt-4 md:mt-0">
                  <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Legal</h4>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Brand Policy</a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Privacy Policy</a>
                  <a className="line-height-3 text-xl block cursor-pointer text-700">Terms of Service</a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
