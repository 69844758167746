import React, { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { LoginDto } from "../../services/auth/auth.dto";
import authService from "../../services/auth/auth.service";
import { useAuth } from "../../utils/auth";
import { useToast } from "../../utils/toast";
import calistayServices from "../../services/calistay/calistay.service";

const Login = () => {
  const [checked, setChecked] = useState(false);
  const auth = useAuth();
  const toast = useToast();

  const navigate = useNavigate();
  const containerClassName = classNames(
    "surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  );

  const formik = useFormik<LoginDto>({
    initialValues: {
      email: "",
      password: ""
    },
    enableReinitialize: true,
    validate: data => {
      const errors: any = {};

      if (!data.email) {
        errors.email = "UserName gereklidir";
      }

      if (!data.password) {
        errors.password = "Password gereklidir";
      }

      return errors;
    },
    onSubmit: values => {
      authService.CreateToken(values).then(async res => {
        if (res.result && !res.result.error) {
          auth.removeUserFromStorage();
          auth.setToken(res.result.data, checked);
          localStorage.setItem("userName", values.email);
          localStorage.setItem("role", res.result.data.role);
          

          await calistayServices.GetMasaNo(values.email).then(res => {
            if (res?.result?.error === null) {
              const data = res.result.data;
              // Eğer data string değilse string'e çevir
              const dataAsString = typeof data === "string" ? data : String(data);
              // Daha sonra parseInt ile sayıya çevir
              const masaNo = parseInt(dataAsString, 10);
              let anketNo = 1;
              if(masaNo === 1 || masaNo === 2 || masaNo === 3){
                anketNo = 1;
              } else if(masaNo === 4 || masaNo === 5 || masaNo === 6){
                anketNo = 2;
              } else if(masaNo === 7 || masaNo === 8 || masaNo === 9){
                anketNo = 3;
              } else if(masaNo === 10 || masaNo === 11 || masaNo === 12){
                anketNo = 4;
              } else if(masaNo === 13 || masaNo === 14){
                anketNo = 5;
              } else if(masaNo === 15 || masaNo === 16){
                anketNo = 6;
              } else if(masaNo === 17 || masaNo === 18 ){
                anketNo = 7;
              }
              if (masaNo){
                localStorage.setItem("anketNo", anketNo.toString());
              }
            } else {
              toast.show("Masa numarası alınamadı.", "error");
            }
          });

          navigate("/", { replace: true });
        } else {
          toast.show(res?.result?.error || "", "error");
        }
      });
    }
  });

  return (
    <form className={containerClassName} onSubmit={formik.handleSubmit}>
      <div className="flex flex-column align-items-center justify-content-center">
        <Link to="/">
          <img src="/images/logoosbuk.png" alt="Sakai logo" className="mb-5 w-6rem flex-shrink-0" />
        </Link>
        <div
          style={{
            borderRadius: "56px",
            padding: "0.3rem",
            background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)"
          }}
        >
          <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
            <div className="text-center mb-5">
              <Link to="/">
                <img src="/images/logoosbuk.png" alt="Image" height="50" className="mb-3" />
              </Link>
              <div className="text-900 text-3xl font-medium mb-3">{"Welcome"}</div>
              <span className="text-600 font-medium">Giriş Yapınız</span>
            </div>

            <div>
              <label htmlFor="email" className="block text-900 text-xl font-medium mb-2">
                {"Kullanıcı Adı"}
              </label>
              <InputText
                id="email"
                type="text"
                placeholder={"Kullanıcı Adınızı Giriniz"}
                className="w-full md:w-30rem"
                value={formik.values.email}
                onChange={e => formik.setFieldValue("email", e.target.value)}
                style={{ padding: "1rem" }}
              />
              {formik.errors.email && <div className="p-error mt-3">{formik.errors.email}</div>}

              <label htmlFor="password" className="block text-900 font-medium text-xl mb-2 mt-5">
                {"Password"}
              </label>
              <div className="login-password">
                <Password
                  inputId="password"
                  value={formik.values.password}
                  onChange={e => formik.setFieldValue("password", e.target.value)}
                  placeholder={"Password"}
                  className="w-full"
                  toggleMask={true}
                  feedback={false}
                  inputClassName="w-full p-3 md:w-30rem"
                ></Password>
                {formik.errors.password && <div className="p-error mt-3">{formik.errors.password}</div>}
              </div>

              {/* <div className="flex align-items-center justify-content-between mb-5 gap-5 mt-5">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="rememberme"
                    checked={checked}
                    onChange={(e) => setChecked(e.checked ?? false)}
                    className="mr-2"
                  ></Checkbox>
                  <label className="text-primary" htmlFor="rememberme">
                    {t("RememberMe")}
                  </label>
                </div>
                <Link
                  to="/forgot-password"
                  className="font-medium no-underline ml-2 text-right cursor-pointer"
                  style={{ color: "var(--primary-color)" }}
                >
                  {t("ForgotPassword")}
                </Link>
              </div> */}
              <Button label={"Login"} className="w-full p-3 text-xl mt-5" type="submit"></Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Login;
