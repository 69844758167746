import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useToast } from "../../utils/toast";
import calistayServices from "../../services/calistay/calistay.service";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

export interface Kisi {
  name: string;
  lastname: string;
  masano: number;
}
const MasaSonucListe = () => {
  const toast = useToast();
  const [kisiler, setKisiler] = useState<Kisi[]>([]);

  useEffect(() => {
    calistayServices
      .MasaSonucList()
      .then(res => {
        if (res?.result?.error === null) {
          setKisiler(res.result.data);
        } else {
          toast.show("Form gönderiminde hata oluştu.", "error");
        }
      })
      .catch(error => {
        toast.show("Sunucu hatası: " + error.message, "error");
      });
  }, []);

  return (
    <>
      <div className="card">
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Oturma Planı</div>
        </div>
        <div className="w-full surface-card">
          <DataTable
            value={kisiler}
            showGridlines
            tableStyle={{ minWidth: "60rem" }}
            rows={500} // Maksimum 5 satır gösterilecek şekilde ayarlandı
            paginator // Sayfalama eklemek için
            scrollable
            columnResizeMode="expand"
            sortField="name" // Sort by "Adı" field
            sortOrder={1} // 1 for ascending, -1 for descending
          >
            <Column field="name" header="Adı"></Column>
            <Column field="lastname" header="Soyadı"></Column>
            <Column field="masano" header="Masa No"></Column>
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default MasaSonucListe;
