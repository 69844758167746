import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useToast } from "../../utils/toast";
import { Button } from "primereact/button";
import calistayServices from "../../services/calistay/calistay.service";
import { CalistayAnket1 } from "../../services/calistay/calistay.dto";

const MasaSonuc = () => {
  const toast = useToast();
  const [name, setSame] = useState<string>("");
  
  const handleSubmit = () => {

      calistayServices
        .MasaSonuc()
        .then(res => {
          if (res?.result?.error === null) {
            toast.show("Form başarıyla kaydedildi!", "success");
          } else {
            toast.show("Form gönderiminde hata oluştu.", "error");
          }
        })
        .catch(error => {
          toast.show("Sunucu hatası: " + error.message, "error");
        });

  };

  return (
    <>
      <h5> Masanız: {name}</h5>

      <div className="mr-4" style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button label="Gönder" onClick={handleSubmit} />
      </div>
    </>
  );
};

export default MasaSonuc;
