import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import "i18next";
import { locale } from "primereact/api";
import { AddTrLocaleforPrimeReact } from "./AddTrLocaleforPrimeReact";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

var i18nextLng = localStorage.getItem("i18nextLng");
if (!i18nextLng) {
  localStorage.setItem("i18nextLng", "tr");
  locale("tr");
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "tr",
    debug: false, // Geliştirme aşamasında hataları görmek için true yapabilirsiniz
    returnNull: false,
    backend: {
      // Doğru yolu belirtiyoruz
      //loadPath: '%PUBLIC_URL%/locales/{{lng}}/{{ns}}.json',
      // Opsiyonel: İstek başarısız olursa yeniden deneme
      retryTimeout: 500,
      retry: 3
    },
    detection: {
      // Dil algılama seçenekleri
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    interpolation: {
      escapeValue: false,
    },
  });

AddTrLocaleforPrimeReact();

export default i18n;
export const languages = {
  tr: "tr-TR",
  en: "en-US",
};