import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { HesaplamaAracAnketRequestDto, FormResponse, RaporlamaRequestDto, GrafikAnketRequestDto, AnketRequestDto } from "./form.dto";

const services = {
  karbonAyakIziForm(dto: HesaplamaAracAnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/hesaplama", updatedDto, config);
  },
  raporForm(dto: AnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/rapor", updatedDto, config);
  },

  GrafikAnket(dto: GrafikAnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/grafik", updatedDto, config);
  },

  egitimAnket(dto: AnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/egitim", updatedDto, config);
  },
  afadAnket(dto: AnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/afad", updatedDto, config);
  },
  haberAnket(dto: AnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/haber", updatedDto, config);
  }
};

export default services;
