import React from "react";
import ReactDOM from "react-dom/client";
import "./lib/i18n";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./assets/styles/layout.scss";
import axios from "axios";
import RootLayout from "./layout/RootLayout";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppRouter from "./lib/AppRouter";
import { AuthProvider } from "./utils/auth";
import { AxiosProvider } from "./utils/axios";
import { ToastProvider } from "./utils/toast";

axios.defaults.baseURL = "https://yunusgns.pythonanywhere.com/api/";
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false
    }
  }
});

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
      <ToastProvider>
        <AxiosProvider>
          <RootLayout>
            <AppRouter />
          </RootLayout>
        </AxiosProvider>
        </ToastProvider>
      </AuthProvider>
    </QueryClientProvider>
  </BrowserRouter>
);
