import React, { useContext, useEffect, useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";

import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useToast } from "../../../utils/toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { click } from "@testing-library/user-event/dist/click";
import { useQuery } from "@tanstack/react-query";
import calistayServices from "../../../services/calistay/calistay.service";
import {
  AlanSonuc,
} from "../../../services/calistay/calistay.dto";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const CalistayAnketSirala1Sonuc = () => {
  const toast = useToast();

  const [items, setItems] = useState<AlanSonuc[]>([]);

  useEffect(() => {
    if (localStorage.getItem("userName") === null) {
      toast.show("Kullanıcı adı bulunamadı.", "error");
      return;
    }

    calistayServices
      .AnketAlanSonuc1(localStorage.getItem("userName") ?? "")
      .then(res => {
        if (res?.result?.error === null) {
          setItems(res.result.data);
        } else {
          toast.show("Form gönderiminde hata oluştu.", "error");
        }
      })
      .catch(error => {
        toast.show("Sunucu hatası: " + error.message, "error");
      });
  }, []);

  return (
    <div className="card ">
      <h5 className="text-center mb-4 font-medium">Yeşil Dönüşüm Stratejik Amaçlar</h5>

      <div className="w-full surface-card">
        <DataTable
          value={items}
          showGridlines
          tableStyle={{ minWidth: "60rem" }}
          rows={500}
          paginator // Sayfalama eklemek için
          scrollable
          columnResizeMode="expand"
          sortField="toplam" // Sort by "Adı" field
          sortOrder={-1} // 1 for ascending, -1 for descending
        >
          <Column field="result" header="İsim"></Column>
          <Column field="grup" header="Masa Geneli Verilen Puan"></Column>
          <Column field="toplam" header="Salon Geneli Verilen Puan"></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default CalistayAnketSirala1Sonuc;
